//FONT
@import "~ui-kit.aspentech.com/dist/themes/default/abstracts/mixins";
@import "~ui-kit.aspentech.com/dist/themes/default/abstracts/variables";

/*Dionne: I added the font*/
@font-face {
    font-family: 'Lato';
    src: local('Lato'), url("../fonts/Lato-Regular.ttf") format("truetype");
    font-style: normal;
    font-display: auto;
}

//.app--public {


    .pipe-seperated-children > * {
        display: inline;

        &::after {
            content: "|";
            display: inline;
            margin: 0 .25em;
        }

        &:last-child {
            &::after {
                display: none;
            } 
        }
    }
    //border: 2px solid olive;
    .l--two-col {
        flex-direction: column-reverse;
    }

    @media (min-width: $bp-large) {
        .l--two-col {
            flex-direction: row;
        }
    }

    .screen--search {
        // border: 2px solid red;
        //width: 102.5em;
        //margin-left: -10%;
        .header__basic {
            margin: auto 0 !important;
            background-color: rgb(1, 1, 51);
            width: 100vw;
        }

        em.highlight {
            font-style: inherit;
            background-color: rgb(204,226,243);
        }
        //AutoSuggest
        .c-search__input-container {
            position: relative;
            flex-grow: 1;

            &--open {
            }
        }

        .c-search__input-text {
            &:-ms-clear {
                display: none;
            }

            &--open {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                border: 1px solid $color-black;
            }

            &--focused {
                outline: none;
            }
        }

        .c-search__input-item__suggestions-container {
            background-color: $color-white;
            padding: .25em;
            border: 1px solid $color-black;
            border-top-width: 0;
            position: absolute;
            left: 0;
            right: 0;
            z-index: 1;
            display: none;

            &--open {
                display: block;
            }
        }

        .c-search__input-item__suggestions-list {
            list-style: none;
            padding: 0;
            margin: 0;
            text-align: left;
        }

        .c-search__input-item__suggestion {
            cursor: pointer;
            padding: .5em 1em;

            &--first {
            }

            &--highlighted {
                background-color: #ddd;
            }
        }

        .c-search__input-item__section-container {
            border-top: 1px dashed #ccc;

            &--first {
                border-top: 0;
            }
        }

        .c-search__input-item__section-title {
            padding: .5em 0 0 .5em;
            font-size: .75em;
            color: #777;
        }
        //TODO: add to ui-kit
        .facets__container {
            .facets__items.panel.active {
                max-height: initial;
                margin-bottom: 1em;


                &.facets__items--industry,
                &.facets__items--application,
                &.facets__items--product_version_facet,
                &.facets__items--product_facet {
                    max-height: 16em;
                    overflow-y: auto;
                }
            }

            .facet__item--no-results {
                padding: .5em;
                text-align: center;
                background-color: rgba($orange, .15);
                font-size: .85em;
            }
        }

        .facets__contextualFilter input {
            margin: .5em 0;
            padding: .25em;
            border-color: $color-gray-35;
        }
        //TODO: remove when we style checkboxes
        // input[type="checkbox"] {
        //     position: static;
        //     left: initial;
        // }
        .c-search__actions {
            font-size: .75em;
            text-align: right;

            a.c-btn {
                display: inline-block;
                width: auto;
                margin: 0 0 0 .5em;
            }
        }

        .c-search__orders {
            display: inline-block;
            display: none; //not needed for ui
            margin-left: 1em;
        }

        .c-search__order {
            display: inline-block;
            padding: 0;

            input {
                position: absolute;
                left: -9999px;
            }

            &--active {
                font-weight: bold;
            }

            .c-search__order-text {
                //TODO: uncomment when icons are available
                // position: absolute;
                // left: -9999px;
            }
        }
        /* list & grid */
        .c-search__modes {
            display: none;
            /*display: inline-block;*/
            margin-left: 0;
        }

        .c-search__mode {
            display: inline-block;
            padding: 0;
            width: 45px;
            height: 45px;
            margin: 0 auto;

            input {
                position: absolute;
                left: -9999px;
            }

            &--active {
                opacity: .8;
            }

            .icon-box {
                width: 45px;
                height: 45px;
                margin: 0 auto;

                svg {
                    height: 33px;
                }
            }

            .c-search__mode-text {
                //TODO: uncomment when icons are available
                // position: absolute;
                // left: -9999px;
            }
        }
        /*list & grid END*/
        .search__header {
            padding-left: 2rem;
        }

        .c-search__box {
            float: none;
        }
        //Dionne's Style
        .c-list {
            margin-top: 1em;
        }

        .c-list__item {
            margin-bottom: 2em;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .c-search__count {
            font-size: .85em;
            margin-top: .3em;
        }

        .c-item {
            margin-bottom: 2em;

            &:last-child {
                margin-bottom: 0;
            }

            .c-item__flag {
                background: $blue-mid;
                width: 260px;
                color: $color-white;
                text-align: right;
                padding-right: 10px;
                margin-top: 10px;
                font-size: 1em;
            }

            .c-card__media {
                text-align: center;
                vertical-align: top;
            }

            &--thumbnail {
                .c-card__media {
                    display: inline-block;
                    width: calc(15% - 1em);
                    margin: 1em 1em 0 0;
                }

                .c-item__body {
                    display: inline-block;
                    width: 85%;
                }
            }

            .c-item__body {
                margin-top: .5em;

                .c-item__headline {
                    //TOD O:I changed the font to 1.3em, the design says 30px but that is too big
                    font-size: 1.3em;
                    color: $blue-mid;
                    font-weight: 400;
                }

                p.c-item__sub-headline {
                    color: #a8a8a8;
                    font-weight: bold;
                    font-size: 1em;
                }

                .c-item__excerpt {
                    font-size: .8em;
                    font-weight: bold;
                    margin-top: .5em;
                }
            }
        }

        .by-relevance {
            border: none;
            color: $color-black;
            font-size: .8em;
            height: 25px;
            text-align: left;
        }

        @media (max-width: 855px) {
            .c-search__limit {
                margin-right: 0px!important;
            }
        } 

        .c-search__limit {
            border: none;
            color: #000;
            font-size: .8em;
            height: 25px;
            text-align: left;
            float: right;
            margin-top: -80px;
            margin-right: 70px;
            
            
        }

        .c-search__filters {
            margin-left: 20px;

            &--inline {
               
                display: inline-block;
            }

            .c-tab {
                border: none;
                color:grey;
                font-size: .8em!important;
                height: 25px;
                text-align: left;
            }
        }

        .c-search__box {
            margin-top: 5px;
        }

        .c-search__results {
            position: relative;
            min-height: 100px;
        }

        .c-item__options {
            .tab-bar {
                color: #818181;
                font-weight: bolder;
                font-size: .8em;
                margin-top: .5em;
            }
        }

        .c-search__active-facets{
            font-size: 1.1em!important;
        }
        
        .support-icon{
            margin-top: 1px;
            cursor: pointer;
            img {
               width: 24px;
            }
        }

        .item__child-list {
            padding: 10px;
            font-size: 1.2em;

            .link {
                display: block;
                padding-left: 3%;
                padding-right: 3%;
                font-weight: 400;
            }

            span {
                margin-left: 10px;
                font-size: .8em;
                padding-left: 2%;
                padding-right: 2%;
            }
        }

        .c-search__actions--clear {
            background-color: transparent;
            border:none;
            color:#0078C9;
            font-size: 1.1em;
            &:hover {
                background-color:transparent;
                border: none;
                color:#0078C9;
            }
        }

        .c-search__actions--share{
            float: right!important;
            width: 80px;
            margin-top: 0;
        }

        @media (max-width: 1280px) {
            .c-search__pagination{
                padding-right: 100px!important;
                
            }
        }

        @media (max-width: 1200px) {
            .c-search__pagination{
                padding-right: 200px!important;
                
            }
        }

        @media (max-width: 1130px) {
            .c-search__pagination{
                padding-right: 150px!important;
                
            }
        }    
        .c-search__pagination{
            padding: 3em 0 3em;
            /*border: 1px solid black;*/
        }
       

        //Dionne's Style END
        .tab-panel {
            margin: 1em 0 1em 2.5em;

            .c-btn {
                margin-bottom: 0;
            }

            & > ul {
                border: 1px solid $color-black;
                border-width: 1px 0;
            }
        }

        .events__actions {
            text-align: right;
            font-size: .8em;
            margin-top: .5em;
        }
        //TODO: put in ui-kit accordion
        .grow {
            flex-grow: 1;
        }

        .tab-bar {
            display: flex;

            .accordion {
                display: inline-block;
            }

            .tab {
                flex-shrink: 1;

                &.end {
                }
            }
        }

        .accordion {
            cursor: pointer;
            transition: 0.4s;
            text-align: left;
            position: relative;
            padding-right: 3em;

            .icon-box {
                display: flex;
                flex-direction: row;
                align-items: center;
                transition: transform 0.4s ease-out;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
            }

            svg {
                height: 1rem;
                width: 1rem;
                fill: $color-utility-neutral;
            }

            &.active {
                .icon-box {
                    transform: rotate(90deg);
                }
            }

            &.active,
            &:hover {
                background-color: $color-utility-neutral-subtle;
            }
        }

        .item__child-list--events {
            font-size: .75em;

            label {
                font-size: 1.1em;
                color: $color-gray-65;
                font-weight: bold;
            }

            .link {
                padding: 0;
            }

            & > .fi {
                flex-direction: column;
                border-left: 1px solid $color-gray-65;
                cursor: pointer;

                &:first-child {
                    border-left: none;

                    &:hover {
                        border-left: none;
                    }
                }

                &:hover {
                    border-left: 1px solid $color-white;
                    box-shadow: 0px 3px 5px 3px rgba($color: $color-gray-65, $alpha: .5);
                }
            }
        }

        .c-btn {
            max-width: 100%;
            cursor: pointer;

            &:hover {
                &:before {
                    box-shadow: none;
                }
            }
        }

        @media (max-width: $bp-large) {
            .l-sidebar {
                margin-bottom: 1em;
                padding-bottom: 2em;
                border-bottom: 1px solid #afafaf;
            }
        }

        .l-sidebar {
            .c-search__facets {
                margin-top: 0px;
                flex: 1;

                .active {
                    height: 100%;
                }
                /* @media all and (min-height: $bp-small) {
                border:1px solid red;
                background-color: hotpink;
                
                }
                @media all and (min-height: $bp-med) {
                border:1px solid green;
                margin-top: 25px;
                }*/
            }

            .c-search__facets-toggle {
                text-align: right;
                padding: 0px;
                border: none;

                svg {
                    margin: 0;
                    padding-right: 0;
                    height: 40px;
                }
            }

            .c-search__facets-form {
                margin-right: 15px;

                .active {
                }
            }
        }

        .facets__slide {
            height: 100%;
            // border-left: 1px solid grey;
            /*:last-child
                border-bottom: 1px solid grey;
            }*/
        }

        .topAd_ {
            width: 80%;
            height: 50px;
        }
    }

    .loader--search {
        svg {
            fill: $blue-mid;
            margin: 2em;
            padding: 1em;
            // border-radius: 1em;
            // background-color: $color-white;
            height: 60px;
            width: 150px;
            // box-shadow: 1px 1px 1px #000;
        }
    }
//}