* {
    outline: none;
}

html, body, div#root {
    margin: 0;
    padding: 0;
}



svg {
    .aaa {
        fill: #231f20
    }

    .aab,
    .aal {
        fill: #0078c9
    }

    .aac {
        font-size: 57.36px;
        font-family: MyriadPro-Regular, Myriad Pro
    }

    .d,
    .aae,
    .aag,
    .aai,
    .aak,
    .aap {
        fill: none
    }

    .d {
        stroke: #fff
    }

    .d,
    .aae,
    .aag,
    .aai,
    .aak,
    .aal {
        stroke-miterlimit: 10
    }

    .aae {
        stroke: #1d77bc
    }

    .aae,
    .aak {
        stroke-width: 4px
    }

    .aaf {
        fill: #1d77bc
    }

    .aag {
        stroke: #000
    }

    .aah {
        fill: #2178bd
    }

    .aai {
        stroke: #231f20;
        stroke-width: 2px
    }

    .aaj {
        fill: #babbbc
    }

    .aak,
    .aal {
        stroke: #0078c9
    }

    .aam,
    .aao {
        fill: #fff
    }

    .aan {
        fill: #139cd8
    }

    .aao {
        fill-rule: evenodd
    }

    .aap,
    .aas {
        stroke: #95989a
    }

    .aaq {
        font-size: 18px;
        font-family: Merriweather-Light, Merriweather;
        font-weight: 300
    }

    .aar {
        fill: #c1bebe
    }

    .aas {
        fill: #e0dddd
    }






    .aea {
        fill: #fff
    }

    .aeb {
        fill: none
    }

    .aeb,
    .aed {
        stroke: #95989a
    }

    .aec {
        fill: #c1bebe
    }

    .aed {
        fill: #e0dddd
    }




    .aga {
        fill: #fff
    }






    .awcls-1 {
        fill: #fff
    }







    .axcls-1 {
        fill: #fff
    }








    .bba {
        font-size: 18px;
        font-family: Merriweather-Light, Merriweather;
        font-weight: 300
    }

    .azcls-1 {
        fill: #fff
    }

    .aycls-1 {
        fill: #fff
    }
}
