//ABSTRACT
@import "~ui-kit.aspentech.com/dist/themes/default/abstracts/mixins";
@import "~ui-kit.aspentech.com/dist/themes/default/abstracts/variables"; 
            
//BASE   
@import "~ui-kit.aspentech.com/dist/themes/default/base/reset";
@import "~ui-kit.aspentech.com/dist/themes/default/base/body";
@import "~ui-kit.aspentech.com/dist/themes/default/components/icons"; 

@import "~ui-kit.aspentech.com/dist/themes/default/base/forms";
@import "~ui-kit.aspentech.com/dist/themes/default/base/global-classes";
@import "~ui-kit.aspentech.com/dist/themes/default/base/headings";
@import "~ui-kit.aspentech.com/dist/themes/default/base/links";
@import "~ui-kit.aspentech.com/dist/themes/default/base/lists";
@import "~ui-kit.aspentech.com/dist/themes/default/base/main";
@import "~ui-kit.aspentech.com/dist/themes/default/base/media";
@import "~ui-kit.aspentech.com/dist/themes/default/base/tables";
@import "~ui-kit.aspentech.com/dist/themes/default/base/text";

//COMPONENTS
@import "~ui-kit.aspentech.com/dist/themes/default/components/forms";
@import "~ui-kit.aspentech.com/dist/themes/default/components/fields";
@import "~ui-kit.aspentech.com/dist/themes/default/components/lists";
@import "~ui-kit.aspentech.com/dist/themes/default/components/overlay";
@import "~ui-kit.aspentech.com/dist/themes/default/components/paper";
@import "~ui-kit.aspentech.com/dist/themes/default/components/accordion";
@import "~ui-kit.aspentech.com/dist/themes/default/components/buttons";
@import "~ui-kit.aspentech.com/dist/themes/default/components/menu-toggle";
@import "~ui-kit.aspentech.com/dist/themes/default/components/text";
@import "~ui-kit.aspentech.com/dist/themes/default/components/cards";
@import "~ui-kit.aspentech.com/dist/themes/default/components/pagination";
@import "~ui-kit.aspentech.com/dist/themes/default/components/video-player";

//LAYOUT
@import "~ui-kit.aspentech.com/dist/themes/default/layout/layout";
@import "~ui-kit.aspentech.com/dist/themes/default/layout/search";

//OVERRIDE
/**
    * Links
    */
a {
    color: $blue-mid;
    font-weight:bold;
    text-decoration: none;
    outline: 0;
    transition: all $anim-speed-quick $anim-ease;

    &:hover, &:focus {
        color: $color-gray-65;
    }
}

label {
    cursor: inherit;
}
