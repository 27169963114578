.explain {
    float: right;
    font-size: .8em !important;

    .explain__link {
        visibility: hidden;
        float: right;
    }
    ul {
        margin-left: 1em;
    }
}
.explain--active, :hover > .explain {
    > .explain__link {
        visibility: visible;
    }
}
   