@import "~ui-kit.aspentech.com/dist/themes/default/abstracts/variables";

.loader {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, .5);

    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
}
  